import React from 'react';

function HeaderVideo () {
    const [ isLoaded, setIsLoaded ] = React.useState(false);

    return (
        <div id="topVideo">
            <video onLoadedData={() => setIsLoaded(true) }
                autoPlay muted loop style={{ filter: 'brightness(0.6)', position: 'absolute', top: '0px', left: '0px' }}>
                <source src="/cup.mp4" type="video/mp4" />
            </video>
            <img src="/cup_full.jpg" alt="Cup"
                style={{ visibility: isLoaded ? "hidden" : "visible", filter: 'brightness(0.6)', position: 'absolute', top: '0px', left: '0px' }} />
        </div>
    )
}

export default HeaderVideo;