import React from 'react'
import { Nav, Navbar, NavItem, NavbarBrand, NavbarToggler, Collapse } from 'reactstrap';

function HeaderSmall(props) {
    const [ isNavOpen, setIsNavOpen ] = React.useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    }

    const scrollTo = (ref) => {
        setIsNavOpen(false);

        const y = ref.current.getBoundingClientRect().top + window.pageYOffset - 280;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    return (
        <div id="header" >
            <Navbar light className="bg-light border" expand="lg" >
                <div className="container">
                    <NavbarBrand className="d-block d-lg-none" style={{ fontSize: '1.5em', letterSpacing: '1.5px' }}>
                        Carlos Machado
                    </NavbarBrand>
                    <NavbarToggler onClick={ toggleNav } />
                    <Collapse isOpen={ isNavOpen } navbar >
                        <Nav navbar>
                            <NavItem onClick={() => { scrollTo(props.projRef) }}>
                                <div >Projects</div>
                            </NavItem>
                            <NavItem onClick={() => { scrollTo(props.expRef) }}>
                                <div>Experience</div>
                            </NavItem>
                            <NavItem onClick={() => { scrollTo(props.eduRef) }}>
                                <div>Education</div>
                            </NavItem>
                            <NavItem onClick={() => { scrollTo(props.abRef) }}>
                                <div>Abilities</div>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </div>
            </Navbar>
        </div>
    )
}

export default HeaderSmall;