import React from 'react';
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';

function MultiImage(props) {

    const fadeProperties = {
        duration: 5000,
        transitionDuration: 2000,
        infinite: true,
        indicators: false,
        arrows: false
    };
	
	const headerTopImage = {
		objectFit: 'cover',
		objectPosition: 'center',
		animationName: 'zoom-image',
		animationDuration: '60s',
		animationIterationCount: 'infinite'
	};

    const imageSlide = props.images.map((image, pos) => {
        return(
            <div className="each-fade" key={ pos }>
                <div className="image-container">
                    <img style={ headerTopImage } src={ image } alt="" width="100%" />
                </div>
            </div>
        );
    });

    return (
        <React.Fragment>
            <Fade {...fadeProperties} >
                { imageSlide }
            </Fade>
        </React.Fragment>
    );
}

export default MultiImage;
