import React from 'react';
import './App.css';
import MainPage from './components/MainComponent';

function App() {

    React.useEffect(() => {
        //window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        //});

        //return (() => window.removeEventListener('resize'))
    });

    return (
        <div className="App">
            <MainPage />
        </div>
    );
}

export default App;
