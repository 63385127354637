import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';

function Abilities() {

    return (
        <div className="abilities-background">
            <div className="container">
                <div className="col-12 col-lg-8 ms-auto me-auto">
                    <div className="text-center border-down">
                        <div className="category-header">Abilities</div>
                    </div>

                    <SkillSection
                        title="Programming Languages"
                        leftSkills={ [
                            { "name": "Kotlin", "points": 5 },
                            { "name": "Java", "points": 5 },
                            { "name": "Swift", "points": 5 },
                            { "name": "Objective-C", "points": 4 },
                            { "name": "JavaScript", "points": 4 }
                        ] }
                        rightSkills={ [
                            { "name": "SQL", "points": 5 },
                            { "name": "HTML", "points": 4 },
                            { "name": "CSS", "points": 4 },
                            { "name": "Python", "points": 4 },
                            { "name": "Scala", "points": 3 }
                        ] } />

                    <div style={{ marginTop: '30px'}}>
                        <SkillSection
                            title="Tools & Frameworks"
                            leftSkills={ [
                                { "name": "Android Studio (4 years)", "points": 5 },
                                { "name": "Xcode (2 years)", "points": 4 },
                                { "name": "Firebase (4 years)", "points": 5 },
                                { "name": "Gradle (4 years)", "points": 4 },
                                { "name": "React (2 years)", "points": 5 },
                                { "name": "Bootstrap (2 years)", "points": 5 },
                                { "name": "MongoDB (1 year)", "points": 4 },
                                { "name": "Express.js (1 year)", "points": 3 }
                            ] }
                            rightSkills={ [
                                { "name": "PTC Creo (2 years)", "points": 5 },
                                { "name": "Ansys Mechanical (2 years)", "points": 4 },
                                { "name": "Windchill (2 years)", "points": 4 },
                                { "name": "AutoCAD (1 year)", "points": 4 },
                                { "name": "Matlab (2 years)", "points": 4 },
                                { "name": "Ansys Fluent (1 year)", "points": 3 }
                            ] } />
                    </div>

                    <div style={{ marginTop: '30px', paddingBottom: '80px' }}>
                        <SkillSection
                            title="Languages"
                            leftSkills={ [
                                { "name": "Portuguese (Native)", "points": 5 },
                                { "name": "English (Fluent - C2)", "points": 5 }
                            ] }
                            rightSkills={ [
                                { "name": "Spanish (Intermediate)", "points": 3 }
                            ] } />
                    </div>
                </div>
            </div>
        </div>
    );
}

function SkillSection(props) {

    const titleStyle = {
        marginBottom: '30px',
        fontSize: '1.5em',
        fontWeight: '400',
        letterSpacing: '1px',
        color: '#1593CB'
    }

    const tableRowStyle = {
        width: '100%',
        fontSize: '1.1em',
        fontWeight: '400',
        color: '#555',
        paddingBottom: '0px',
        paddingTop: '0px'
    }

    return (
        <div>
            <div style={ titleStyle }>{ props.title }</div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <table style={{ width: '100%' }}>
                        <tbody style={{ width: '100%' }}>
                            { 
                                props.leftSkills.map((skill) => {
                                    return (
                                        <tr key={ skill.name } style={ tableRowStyle }>
                                            <td style={{ textAlign: 'left' }}>{ skill.name }</td>
                                            <td style={{ textAligh: 'right', width: '110px' }}><SkillStars nr={ skill.points } /></td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-12 col-md-6">
                <table style={{ width: '100%' }}>
                        <tbody style={{ width: '100%' }}>
                            { 
                                props.rightSkills.map((skill) => {
                                    return (
                                        <tr key={ skill.name } style={ tableRowStyle }>
                                            <td style={{ textAlign: 'left' }}>{ skill.name }</td>
                                            <td style={{ textAligh: 'right', width: '110px' }}><SkillStars nr={ skill.points } /></td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

function SkillStars(props) {
    const Icon = (props) => {
        return (
            <FontAwesomeIcon style={{ fontSize: '0.9em', marginLeft: '4px', color: `${ props.color }`}} icon={ faCertificate } />
        );
    }
    
    const stars = [];

    for (var i = 0; i < 5; i++) {
        if (i < props.nr) {
            stars.push(<Icon color="#1593CB" key={ i } />);

        } else {
            stars.push(<Icon color="#BBBBBB" key={ i } />);
        }
    }
    return (
        <React.Fragment>
            { stars }
        </React.Fragment>
    );
}

export default Abilities;