import React from 'react';
import MultiImage from './elements/MultiImage';

function Projects() {

    return (
        <div style={{ background: 'white' }}>
            <div className="container">
                <div className="col-12 col-lg-10 ms-auto me-auto">
                    <div className="text-center border-down">
                        <div className="category-header">Projects</div>
                        <div className="category-description">
                            My most important personal projects, code entirely written by me. 
                        </div>
                    </div>
                    <div className="project-margin-bottom"><Tripfolio /></div>
                    <div className="project-margin-bottom"><PorArte /></div>
                    <div className="project-margin-bottom"><BondedWorld /></div>
                    <div style={{ paddingBottom: '100px' }}><OnFrame /></div>
                </div>
            </div>
        </div>
    );
}

function Tripfolio() {

    return (
        <div>
            <div className="row">
                <div className="col-12 col-lg-7">
                    <div className="project-title">Tripfolio</div>
                    <div className="project-description">
                        Tripfolio is an Android Application that allows its users to track all the places they have been.
                        Users mark locations by adding photos or pins, and the application displays them on a map, colours the countries (and regions for some countries),
                        provides insightful statistics and can automatically determine on how many UNESCO&#174; World heritage Sites (and for many other lists) they have been.
                        <br/><br/>
                        The application also features an online component. Logged users, besides having their information synched online,
                        can add other users on the application as friends, and share their profiles and recent activity with each other.
                    </div>
                    <div className="project-technologies">
                        <div><b>Technologies:</b></div>
                        <span>
                            Made in Android Studio using Kotlin and Java, SQLite for the database and JavaScript for interactions with the WebView.
                            Firebase is used for the backend service.
                            <br/><span style={{ fontWeight: '600' }}>APIs Used:</span> Google Maps, Mapbox, OpenStreetMaps and Wikipedia.
                        </span>
                    </div>
                </div>
                <div className="col-12 col-lg-5">
                    <div className="d-none d-lg-block">
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <img src="/tripfolio_s5.png" width="220px" style={{ position: 'absolute', top: '20px', left: '-10px' }} alt="" />
                            <img src="/tripfolio_s4.png" width="220px" style={{ position: 'absolute', top: '80px', right: '0px' }} alt="" />
                        </div>
                        <div style={{ position: 'relative', top: '400px', left: '0px', zIndex: '5' }}>
                            <a href="https://play.google.com/store/apps/details?id=com.tripfolio" target="_blank" rel="noopener noreferrer">
                                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'
                                    width="180" height="80" />
                            </a>
                        </div>
                    </div>
                    <div className="d-block d-lg-none">
                        <img src="/tripfolio_s1.png" width="100%" alt="" style={{ marginTop: '10px' }} />
                        <div style={{ marginTop: '0px' }}>
                            <a href="https://play.google.com/store/apps/details?id=com.tripfolio">
                                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'
                                    width="180" height="80" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>        
        </div>
    );
} 

function PorArte() {
    return (
        <WebsiteSection
            title="PorArte"
            link="https://porarte.pt"
            images={ ["/porarte_s1.png", "/porarte_s2.png", "/porarte_s3.png"] }
            description={
                <React.Fragment>
                    An e-commerce platform to help independent artists to expose and sell their artworks online, enabling them to reach a far greater audience.
                    Artists can upload their artworks (either paintings, sculptures, drawings or photographs) to exhibit or sell directly on the website, 
                    providing a much needed source of income.
                    <br/>The platform is currently undergoing final tests before official launch, and serves only the Portuguese market.
                </React.Fragment>
            }
            technologies={
                <React.Fragment>
                    Front-end developed using React, Redux and Bootstrap, back-end developed using Node.js with the Express Framework and MongoDB for the database.
                    <br/><span style={{ fontWeight: '600' }}>APIs Used:</span> Stripe and Paypal.   
                </React.Fragment>
            }
            alignRight={ true } />
    );
}

function BondedWorld(props) {

    return (
        <WebsiteSection
            title="BondedWorld"
            link="https://bondedworld.com"
            images={ ["/bondedworld_s1.png", "/bondedworld_s2.png", "/bondedworld_s3.png"] }
            description={
                <React.Fragment>
                    BondedWorld is a website that provides insightful travel guides of various places around the world. 
                    These guides contain information to help people in enjoying the best that each location has to offer,  
                    from the main tourist attractions, to the "off the beaten path" places where they can truly connect with the local culture, 
                    including the cheap food stands that every local visits.
                    <br/>The website also has tips for buying flights, insurance and how to get a visa from various countries.  
                </React.Fragment>
            }
            technologies={
                <React.Fragment>
                    Front-end developed using Gatsby, React and Bootstrap.
                </React.Fragment>
            }
            alignRight={ false } />
    );
}

function OnFrame() {
    return (
        <WebsiteSection
            title="On Frame"
            link="https://onframe.photos"
            images={ ["/onframe_s1.png", "/onframe_s2.png", "/onframe_s3.png"] }
            description={
                <React.Fragment>
                    A personal website to showcase some of the photos I have taken on my journeys.
                </React.Fragment>
            }
            technologies={
                <React.Fragment>
                    Front-end developed using React and Bootstrap.
                </React.Fragment>
            }
            alignRight={ true } />
    );
}


function WebsiteSection(props) {

    const websiteImages = (
        <div className="col-12 col-lg-5" key={ 1 }>
            <div className="website-screenshot">
                <a href={ props.link } target="_blank" rel="noopener noreferrer">
                    <MultiImage images={ props.images } />
                </a>
            </div>
            <div className="d-none d-lg-block" style={{ marginBottom: '10px', marginTop: '20px', textAlign: 'left' }}>
                <a className="inherit" href={ props.link } target="_blank" rel="noopenner noreferrer"
                    style={{ color: '#777', letterSpacing: '1px'  }}>{ props.link }</a>
            </div>
        </div>
    );

    const websiteDescription = (
        <div className="col-12 col-lg-7" key={ 2 }>
            <div className="project-description">
                { props.description }
            </div>
            <div className="project-technologies">
                <div><b>Technologies:</b></div>
                <span>
                    { props.technologies }    
                </span>
            </div>
        </div>
    );

    const websiteTab = props.alignRight ? [ websiteImages, websiteDescription ] : [ websiteDescription, websiteImages ];

    return (
        <div className={ props.alignRight ? "screen-align-text" : ""}>
            <div className="col-12">
                <div className="project-title">{ props.title }</div>
                <div className="d-block d-lg-none" style={{ marginBottom: '10px', marginTop: '-10px' }}>
                    <a className="inherit" href={ props.link } target="_blank" rel="noopenner noreferrer"
                        style={{ color: '#777', letterSpacing: '1px'  }}>{ props.link }</a>
                </div>
            </div>
            <div className={ props.alignRight ? "row" : "row flex-column-reverse flex-lg-row" }>
                { websiteTab }
            </div>     
        </div>
    );
}


export default Projects;