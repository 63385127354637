import React from 'react';
import Section from './elements/Section';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';


function Education() {
    const [ isEduExpanded, setEduExpanded ] = React.useState(false);

    return (
        <div className="education-background">
            <div className="container">
                <div className="col-12 col-lg-10 ms-auto me-auto" style={{ paddingBottom: '80px' }}>
                    <div className="text-center border-down">
                        <div className="category-header">Education</div>
                    </div>
                    <div className="category-description"></div>
                    <Section
                        isInverted={ true }
                        title="Master’s Degree in Mechanical Engineering"
                        duration="2012 - 2017"
                        institution="Faculty of Engineering - University of Porto"
                        description={ <div>
                            Five-year cycle of studies, consisting in 3 years for the bachelor’s degree plus 2 years for the master’s degree.
                            <br/><br/>
                            Specialized in Structural Engineering and Machine Design.
                            <br/><br/>
                            <span style={{ fontWeight: '600' }}>Master’s Thesis:</span> Development of an acoustic computational software to analyse composite and sandwich panels.
                        </div> }
                    />
                    <Section
                        isInverted={ true }
                        title="Bachelor’s Degree in Software Engineering"
                        duration="2017 - 2020"
                        institution="Faculty of Engineering - University of Porto"
                        description={ <div>
                            Three-year cycle of studies, emphasizing in algorithms, application development, 
                            systems architecture, software testing and debugging.
                            <br/><br/>
                            Concurrent with this bachelor, I was also working full-time as a Mechanical Engineer.
                        </div> }
                    />
                    <div>
                        <Section
                            isInverted={ true }
                            title="Computer Science"
                            duration="2020 - Present"
                            institution="Online Education"
                            description={ <div>
                                To further enchance my skills, I continuously enroll in new online courses that I believe will
                                contribute positively to my career path.
                                <br/><br/>
                                <span className="description-link" style={{ cursor: 'pointer' }} onClick={() => setEduExpanded(!isEduExpanded)}><FontAwesomeIcon icon={ isEduExpanded ? faSortUp : faSortDown } style={{ marginBottom: `${isEduExpanded ? '-3px' : '3px'}`, marginRight: '2px' }} /> Click here</span> for the full list of courses completed.
                                </div>
                            } />
                    </div>
                    <div style={{ marginTop: '-60px' }}>
                        <div className={`${isEduExpanded ? 'edu-list-expanded' : 'edu-list-collapsed'}`} style={{ overflowY: "hidden", overflowX: 'visible' }}>
                            <div style={{ fontSize: '0.8em', position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
                                <OnlineEducation />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function OnlineEducation() {
    return (
        <div style={{ marginTop: '10px' }}>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <ul className="edu-list">
                    <   li>Android Developer Nanodegree (Google) on Udacity:
                            <ul>
                                <li>Developing Android Apps</li>
                                <li>Advanced Android App Development</li>
                                <li>Firebase In A Weekend</li>
                                <li>Gradle for Android and Java</li>
                                <li>Material Design for Android Developers</li>
                                <li>Capstone Project</li>
                            </ul>
                        </li>
                        <li>Developing Apps for iOS (Stanford University)</li>
                        <li>Full Stack Web Development with React (The Hong Kong University of Science and Technology) on Coursera:
                            <ul>
                                <li>Front-End Web UI Frameworks and Tools: Bootstrap 4</li>
                                <li>Front-End Web Development with React</li>
                                <li>Multiplatform Mobile App Development with React Native</li>
                                <li>Server-side Development with NodeJS, Express and MongoDB</li>
                            </ul>
                        </li>
                        <li>Front-End Javascript Frameworks: Angular (The Hong Kong University of Science and Technology) on Coursera</li>
                        <li>Full-Stack Web Development on fullstackopen.com</li>
                    </ul>
                </div>
                <div className="col-12 col-lg-6">
                    <ul className="edu-list">
                        <li>Deep Learning and Neural Networks Specialization (deeplearning.ai) on Coursera:
                            <ul>
                                <li>Neural Networks and Deep Learning</li>
                                <li>Improving Deep Neural Networks: Hyperparameter tuning, Regularization and Optimization</li>
                                <li>Structuring Machine Learning Projects</li>
                                <li>Convolutional Neural Networks</li>
                                <li>Sequence Models</li>
                            </ul>
                        </li>
                        <li>Functional Programming in Scala Specialization (École Polytechnique Fédérale de Lausanne) on Coursera:
                            <ul>
                                <li>Functional Programming Principles in Scala</li>
                                <li>Functional Program Design in Scala</li>
                                <li>Parallel Programming</li>
                                <li>Big Data Analysis with Scala and Spark</li>
                            </ul>
                        </li>
                        <li>Game Theory (Stanford University) on Coursera</li>
                        <li>Advanced MATLAB for Scientific Computing (Stanford University)</li> 
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Education;