import React from 'react';
import PropTypes from 'prop-types';

function Section(props) {

    const titleStyle = {
        color: '#1593CB',
        fontWeight: '600',
        letterSpacing: '0.5px',
        marginBottom: '8px'
    };

    const durationStyle = {
        fontWeight: '400',
        fontSize: '0.9em',
        color: '#777',
        marginBottom: '10px'
    };

    const locationStyle = {
        fontWeight: '600',
        fontSize: '0.85em',
        color: '#3a3a3a',
        marginBottom: '8px'
    };

    const institutionStyle = {
        fontWeight: '600',
        letterSpacing: '0.5px',
        marginBottom: '8px'
    };

    const descriptionStyle = {
        fontSize: '0.9em',
        fontWeight: '400',
        letterSpacing: '0.5px',
        lineHeight: '140%',
        textAlign: 'justify'
    };

    if (props.isInverted) {
        return (
            <div style={{ marginBottom: '60px' }}>
                <div className="row flex-column-reverse flex-md-row">
                    <div className="col-12 col-md-8 pe-lg-4">
                        <div style={ institutionStyle }>{ props.institution }</div>
                        <div style={ descriptionStyle }>{ props.description }</div>
                    </div>
                    <div className="col-12 col-md-4" style={{ textAlign: 'left' }}>
                        <div style={ titleStyle }>{ props.title }</div>
                        <div style={ durationStyle }>{ props.duration }</div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ marginBottom: '40px' }}>
                <div className="row">
                    <div className="col-12 col-md-4 experience-text">
                        <div style={ titleStyle }>{ props.title }</div>
                        <div style={ locationStyle }>{ props.location }</div>
                        <div style={ durationStyle }>{ props.duration }</div>
                    </div>
                    <div className="col-12 col-md-8 ps-lg-4">
                        <div style={ institutionStyle }>{ props.institution }</div>
                        <div style={ descriptionStyle }>{ props.description }</div>
                    </div>
                </div>
            </div>
        );
    }
}

Section.defaultProps = {
    isInverted: false
};

Section.propTypes = {
    isInverted: PropTypes.bool.isRequired
};


export default Section;