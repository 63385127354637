import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeSquare, faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons';


function Footer() {

    const iconStyle = {
        fontSize: '2em',
        marginRight: '16px'
    };

    return (
        <div className="footer-background" style={{ overflow: 'hidden' }}>
            <div className="container">
                <div className="text-center">
                    <div className="border-down" style={{ display: 'inline-block' }}>
                        <div className="category-header" style={{ color: 'white' }}>Contact</div>
                        <div className="category-description" style={{ color: 'white' }}>
                            If you want to get in touch, you can contact me by the means bellow
                        </div>
                    </div>
                </div>
                <div className="footer-icons">
                    <div style={{ height: '100%', display: 'table', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div style={{ display: 'table-row'}}>
                            <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                <FontAwesomeIcon icon={ faEnvelopeSquare } style={ iconStyle } />
                            </div>
                            <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                <div style={{ marginBottom: '2px' }}>
                                    <a href="mailto:carlosafmachado@gmail.com" className="inherit">carlosafmachado@gmail.com</a>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'table-row'}}>
                            <div style={{ height: '20px' }}></div>
                        </div>
                        <div style={{ display: 'table-row' }}>
                            <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                <FontAwesomeIcon icon={ faPhoneSquareAlt } style={ iconStyle } />
                            </div>
                            <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                <div style={{ marginBottom: '2px' }}>+351 917 202 061</div>
                            </div>
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    );
}

export default Footer;