import React from 'react';
import Header from './Header';
import HeaderSmall from './HeaderSmall';
import Projects from './Projects';
import Experience from './Experience';
import Education from './Education';
import Abilities from './Abilities';
import Footer from './Footer';

import HeaderVideo from './elements/HeaderVideo';

function MainPage() {
    const [ indexPos, setIndexPos ] = React.useState(0);

    const projRef = React.useRef();
    const expRef = React.useRef();
    const eduRef = React.useRef();
    const abRef = React.useRef();

    const handleScroll = () => {
        if (projRef.current !== undefined && abRef.current !== undefined &&
            eduRef.current !== undefined && expRef.current !== undefined) {

            if (abRef.current.getBoundingClientRect().top <= 1) {
                if (indexPos !== 3) setIndexPos(3)
                
            } else if (eduRef.current.getBoundingClientRect().top <= 1) {
                if (indexPos !== 2) setIndexPos(2);

            } else if (expRef.current.getBoundingClientRect().top <= 1) {
                if (indexPos !== 1) setIndexPos(1);

            } else if (indexPos !== 0) {
                setIndexPos(0);
            }
        }
    }

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll) }      
    });

    return (
        <div style={{ position: 'relative' }}>
            <div className="double-header-height" style={{ position: 'relative', top: '0px' }}>
                <div className="header-height" style={{ position: 'sticky', top: '0px' }}>
                    <HeaderVideo />
                </div>
            </div>
            <div style={{ position: 'absolute', top: '0px', width: '100%' }}>
                <div style={{ direction: 'rtl' }}>
                    <div style={{ direction: 'ltr' }}>
                        <Header onArrowClick={() => { projRef.current.scrollIntoView() }} />
                    </div>

                    <div className="sticky-header d-none d-lg-block">
                        <Index projRef={ projRef } expRef={ expRef } eduRef={ eduRef } abRef={ abRef }
                            indexPos={ indexPos } setIndexPos={ setIndexPos } />
                    </div>
                    <div className="sticky-header d-block d-lg-none" style={{ direction: "ltr", width: "100%", zIndex: '20' }}>
                        <HeaderSmall projRef={ projRef } expRef={ expRef } eduRef={ eduRef } abRef={ abRef } />
                    </div>
                    <div className="main-content">
                        <div ref={ projRef }><Projects /></div>
                        <div ref={ expRef }><Experience /></div>
                        <div ref={ eduRef }><Education /></div>
                        <div ref={ abRef }><Abilities /></div>
                    </div>
                </div>
                <div style={{ width: '100%' }}>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

function Index (props) {

    const indexNames = [
        { "name": "Projects", "ref": props.projRef },
        { "name": "Experience", "ref": props.expRef },
        { "name": "Education", "ref": props.eduRef },
        { "name": "Abilities", "ref": props.abRef }
    ];

    return (
        <div>
            { indexNames.map((idx, pos) => {
                    let background;
                    if (pos === props.indexPos) {
                        background = '#1593CB';
                    } else {
                        background = '#222222';
                    }

                    return (                    
                        <div style={{ background: `${background}`, position: 'relative' }}
                            className="side-nav-item"
                            key={ pos }
                            onClick={() => { idx.ref.current.scrollIntoView() }}>
                            <div className="vertical-center">
                                <div>{ idx.name }</div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

export default MainPage;