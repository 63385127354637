import React from 'react';
import Section from './elements/Section';

function Experience() {
    return (
        <div className="experience-background">
            <div className="container">
                <div className="col-12 col-lg-10 ms-auto me-auto">
                    <div className="text-center border-down">
                        <div className="category-header">Experience</div>
                    </div>
                    <div className="category-description"></div>
                    <Section
                        title="Android Software Engineer"
                        duration="Jan 2022 - Mar 2024"
                        location="London, United Kingdom"
                        institution="Citymapper, by Via Transportation"
                        description={
                            <div>
                                Part of the mobile engineering team, focused on improving the quality, user experience, and 
                                building new features for an Award Winning App which millions of people worldwide rely upon daily.
                                <br/><br/>
                                Engaged with all the different aspects of the app development life-cycle,
                                from working collectively on bringing new functionalities to the app, 
                                to writing comprehensive tests (both unit tests, instrumentation tests and screenshot test), 
                                refactoring code, and fixing new and existing crashes. 
                                Also took full ownership of new features, working closely with the design and product teams,
                                assuring the product we ship meets our standards of quality.
                                <br/><br/>
                                After the first year, I extended my role and joined the iOS Team as well, developing the same features simultaneously for both sides,
                                helping bring parity to both platforms. Used XCode for development on iOS, coding mostly in Swift (and Objective-C for legacy code),
                                with an equal use of UIKit and Swift UI. 
                                <br/><br/>
                                For Android development working with Android Studio, coding mostly in Kotlin (and Java for legacy code) with extensive use of Jetpack Compose
                                for the UI. Used Gradle to help optimise and automate some tasks.
                            </div>
                        } />
                    <Section
                        title="Full Stack Software Engineer"
                        duration="Sep 2020 - Jan 2022"
                        location="Porto, Portugal"
                        institution="IAPMEI - Agency for Competition and Inovation"
                        description={ 
                            <div>
                                Funded by the StartUP Voucher program, I was part of an entrepreneurial team trying to create a business based on an online e-commerce platform.
                                <br/><br/>
                                Being part of the software engineer team, my main functions involved the designing the architecture for the software,
                                and the consequent implementation, testing and deployment of the website. 
                                On the front-end, worked with frameworks and technologies such as React, Bootstrap and Webpack.
                                The back-end involved following REST architecture, and working with Node.js and the Express framework, setting WebSockets and
                                testing with Postman.
                                <br/><br/>
                                Other activities included interacting with possible clients and partners, setting a business model canvas and a business plan,
                                studying market shares, segmentation and competition.
                            </div>
                        } />
                    <Section
                        title="Mechanical Design Engineer"
                        duration="Oct 2017 - May 2020"
                        location="Porto, Portugal"
                        institution="Efacec Power Solutions"
                        description={ 
                            <div>
                                Accountable for the design and structural analysis of mechanical components for High and Medium Voltage Switchgear (using Creo and Ansys).
                                Focused on reducing both material and manufacturing costs and maximizing the product lifespan.
                                <br/><br/>
                                Other activities included:
                                <ul>
                                    <li>Creating new functionalities for the PTC Creo design software (using Java and the PTC Creo Object TOOLKIT);</li>
                                    <li>Direct contact and negotiation with suppliers;</li>
                                    <li>Structural calculation, simulation and failure mode and effects analysis of industrial machinery to comply with CE marking;</li>
                                    <li>Heat transfer and flow simulations using Ansys Fluent.</li>
                                </ul>
                            </div>
                        } />
                    <div style={{ paddingBottom: '60px' }}>
                        <Section
                            title="Dissertation Research"
                            duration="Feb 2017 - Jul 2017"
                            location="Porto, Portugal"
                            institution="INEGI - Institute of Science and Innovation in Mechanical and Industrial Engineering"
                            description={ 
                                <div>
                                    Research fellowship as part of the Master's Thesis.
                                    <br/><br/>
                                    Tasked with creating algorithms based on advanced discretization techniques (Finite Elements and Meshless Methods) capable of performing acoustical analysis on composite and sandwich panels.
                                    Developed using Matlab.
                                </div>
                            } />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Experience;