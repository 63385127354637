import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAtlas, faEnvelopeSquare, faPhoneSquareAlt, faChevronDown } from '@fortawesome/free-solid-svg-icons';

function Header(props) {
    const iconStyle = {
        fontSize: '1.5em',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '6px'
    }

    return (
        <div className="full-page-top header-height">
            <div className="vertical-center">
                <div className="container">
                    <div className="col-12">
                        <h1 className="name-style">Carlos Machado</h1>
                    
                        <div className="col-12 col-lg-10 ms-auto me-auto">
                            <div className="subname-style">
                                Welcome To My Online Resume
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-8 description-text-style">
                                    <div className="me-md-2">
                                        I am a software developer, with experience creating full stack applications for mobile and web platforms. 


                                        As a Mechanical Engineer graduate, self-taught Programmer and passionate learner, I strive to improve myself everyday and to help those around me succeed along the way.
                                        <br/>Scroll down to find more about my work. 
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4" style={{ letterSpacing: '1.5px', fontWeight: '400', textAlign: 'left' }}>
                                    <table>
                                        <tbody>
                                            <tr><td><FontAwesomeIcon icon={ faAtlas } style={ iconStyle } /></td><td style={{ verticalAlign: 'middle' }}> Portugal</td></tr>
                                            <tr><td><FontAwesomeIcon icon={ faEnvelopeSquare } style={ iconStyle } /></td><td style={{ verticalAlign: 'middle' }}> <a href="mailto:carlosafmachado@gmail.com" className="inherit" >carlosafmachado@gmail.com</a></td></tr>
                                            <tr><td><FontAwesomeIcon icon={ faPhoneSquareAlt } style={ iconStyle } /></td><td style={{ verticalAlign: 'middle' }}> +351 917 202 061</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="side-nav-item d-none d-lg-block" style={{ position: 'absolute', bottom: '0px',  borderRadius: '10px 10px 0px 0px', fontSize: '2em' }}
                onClick={() => props.onArrowClick()}>
                <div className="vertical-center">
                    <FontAwesomeIcon className="top-arrow" icon={ faChevronDown } />
                </div>
            </div>
        </div>
    
    );
}

export default Header;